import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTasks, faClock, faCog, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {Link, useOutletContext, NavLink, useParams} from 'react-router-dom';
import {useState, useEffect, useContext} from 'react';
import Tabs from './Tabs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Context from './Context';
import Logo from '../Capture2.PNG';
import OverlaySpinner from '../OverlaySpinner';
//import jsPDFInvoiceTemplate from "jspdf-invoice-template";

//import jsPDFInvoiceTemplate, { OutputType, jsPDF } from "jspdf-invoice-template";

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const SoloJob = ({soloCustomer, setSoloCustomer, setSpinner, soloJobDetails}) => {

	const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId, sign, setSign] = useContext(Context);

	//const [tasks, setTasks, tasksBckUp, setTasksBckUp, assign, setAssign, id, setId, soloCustomer, setSoloCustomer] = useOutletContext();
	//console.log(soloCustomer[0].Last_Name);
	//soloCustomer[0]?console.log(soloCustomer[0]):console.log('');

	const custObj = {...soloCustomer};

	const [thisName, setThisName] = useState(soloCustomer?soloCustomer[0].Name:'');
	const [latName, setLatName] = useState(soloCustomer?soloCustomer[0].Last_Name:'');
	const [thisEmail, setThisEmail] = useState(soloCustomer?soloCustomer[0].Email:'');
	const [phone, setPhone] = useState(soloCustomer?soloCustomer[0].Phone_Number:'');
	const [address, setAddress] = useState(soloCustomer?soloCustomer[0].Address:'');
	const [save, setSave] = useState(true);
	const [dataUrl, setDataUrl] = useState(null);
	const [loader, setLoader] = useState(false);
	//const [taskData, setTaskData] = useState();
	//const [nowTest, setNowTest] = useState();

	const [origin, setOrigin] = useState('2 Nelson Cres, Malvern, Queensburgh, 4055');
	const [destination, setDestination] = useState();
	const [distance, setDistance] = useState();

	const distanceCalculator = async() => {

			console.log(address);
			const API_KEY = 'AIzaSyDENioKxXlEV1wxt30v4YDNo1QuvOryxKU';

			const res = await fetch(`https://api.te-amo.co.za/public/api/tasks/cal/distance?address=J1919 Umlazi Sihayo RD 4031&origin=2 Nelson Cres, Malvern, Queensburgh, 4055&API_KEY=AIzaSyDENioKxXlEV1wxt30v4YDNo1QuvOryxKU`);
			const data = await res.json();

			console.log(data);
	}

	const loadSig = async() => {
			window.scrollTo(0, 0);
	}

	useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

	let { idReal } = useParams();

	const headingTitles = [
			'Customer',
			'Email',
			'Address',
			'Job Type',
			'Serial Number',
			'Condition of Item',
			'Client Aware',
			'Release Date',
			'Assessment Fee',
			'Description'
	];

	const headingTitles2 = [
			'Contact',
			'Mobile',
			'Start Date',
			'Assigned Employees',
			'Make & Model No',
			'Released By',
			'Book In Date',
			'Accessories',
			'Reference No'
	];

	const custDetails = [
			thisName,
			thisEmail,
			address,
			soloJobDetails?.Task_Type,
			soloJobDetails?.Serial_number,
			soloJobDetails?.Condition_item,
			'True',
			'',
			soloJobDetails?.Assement_fee,
			soloJobDetails?.Description_of_job?
			soloJobDetails?.Description_of_job:(soloJobDetails?.Task_Name?soloJobDetails?.Task_Name:soloJobDetails?.Intallation_type)
	];

	const jobDetails = [
			thisName,
			phone,
			soloJobDetails?.created_at.slice(0,10),
			(() => {
	        try {
	            const assignedTo = JSON.parse(soloJobDetails?.Assigned_to);
	            return Array.isArray(assignedTo) ? assignedTo.map((tech) => tech.label) : '';
	        } catch (e) {
	            return '';
	        }
	    })(),
			soloJobDetails?.Model_make,
			'',
			soloJobDetails?.created_at.slice(0,10),
			soloJobDetails?.Accessories,
	];

	function bodyRows(rowCount) {
	  rowCount = rowCount || 10;
	  var body = [];
	  for (var j = 1; j <= rowCount; j++) {
	    body.push({
	      id: headingTitles[j-1],
	      name: custDetails[j-1],
	      email: headingTitles2[j-1],
	      city: jobDetails[j-1],
	      //expenses: 'faker.finance.amount()',
	    })
	  }
	  return body
	}

	const table = async() => {
		//const doc = new jsPDF();
		console.log(sign);
		const api_key = 'AIzaSyDENioKxXlEV1wxt30v4YDNo1QuvOryxKU';
		const res = await fetch(`https://api.te-amo.co.za/public/api/tasks/cal/distance?address=${address}&origin=${origin}&API_KEY=${api_key}`);
		const data = await res.json();

		console.log(data);

		setTimeout(async() => {
			const response = await fetch(`https://api.te-amo.co.za/public/api/tasks/view/sign/${soloJobDetails?.id}`);
			const contentType = response.headers.get("content-type");
			const blob = await response.blob();
			
			const reader = new FileReader();
		    reader.onloadend = () => {
		      setDataUrl(reader.result);
		    };
		  	reader.readAsDataURL(blob);
		}, 50000);
		
		console.log(dataUrl);

		var doc = new jsPDF('p', 'pt')
			doc.addImage(Logo, 'JPEG', 40, 50 );

			doc.setFont('helvetica', 'normal'); // Set font and style
			doc.setFontSize(9); // Set font size
			doc.text('Te-Amo Business Solutions', 440, 60);
			doc.text('2 Nelson Crescent, Malvern, Queensburgh, 4055', 355, 70);
			doc.text('0314633124', 500, 80);
			doc.text('help@te-amo.co.za', 472, 90);
			doc.text('Company No.: 2016/163396/07', 425, 100);
			doc.text('VAT Reg. No.: 4900275571', 440, 110);
		  //doc.text('Te-Amo Business Solutions', 356, 70, {  fontSize: 14, fontStyle: 'normal' });

		  var raw = bodyRows(10);
		  var body = [];

		  for (var i = 0; i < raw.length; i++) {
		    var row = []
		    for (var key in raw[i]) {
		      row.push(raw[i][key])
		    }
		    /*if (i % 5 === 0) {
		      row.unshift({
		        rowSpan: 5,
		        content: i / 5 + 1,
		        styles: { valign: 'middle', halign: 'center' },
		      })
		    }*/
		    body.push(row);
		  }

		  doc.autoTable({
		    startY: 130,
		    head: [
		      [
		        {
		          content: 'Job Detail',
		          colSpan: 4,
		          styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
		        },
		      ],
		    ],
		    body: body,
		    theme: 'grid',
		    bodyStyles: { font: 'helvetica', cellWidth: 'auto', minCellWidth: 110 },
			   columnStyles: {
	        0: { fillColor: [240, 240, 240] }, // Apply red fillColor to the first column
	        2: { fillColor: [240, 240, 240] } // Apply red fillColor to the third column
	    		}
		  });

		  doc.autoTable({
		    startY: doc.lastAutoTable.finalY + 10,
		    head: [
		      [
		        {
		          content: 'Attachments - No Items to Display',
		          colSpan: 4,
		          styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
		        },
		      ],
		    ],
		  });

		  doc.autoTable({
		    startY: doc.lastAutoTable.finalY + 10,
		    head: [
		      [
		        {
		          content: 'Standard Terms and Conditions',
		          colSpan: 4,
		          styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
		        },
		      ],
		    ],
		    body: [
						    ['Disclaimer:\nTe-Amo Business Solutions will only perform and provide services, repairs, and upgrades as requested by the Client. There may be circumstances under which Client’s item cannot be repaired. It will have to be rebuilt or upgraded & length of time required to service/repair cannot be predicted. During the Repair process, there is a potential for data loss. An estimate of cost for work will be provided before performing services and or repairs. Estimates are not guaranteed. Not all services can be completed on-site and must be completed at our offices. In the case that there is an unforeseen deviation, beyond the above estimated amount, every effort will be made to contact you and inform you of the situation and receive authorization to continue or stop at the estimate limit. If Client cannot be reached, work will stop until contact is established. Once reached, Clients decision to continue or stop will be honored. Only written communication will be used to confirm approvals of services/repairs, unless otherwise specified on this Job Card.\nTe-Amo Business Solutions will not be held responsible for items under warranty/guarantee.\nPLEASE NOTE: ALL DATA WILL BE LOST UNLESS OPTION TO BACK-UP IS SELECTED. ITEMS NOT COLLECTED WITHIN 7 DAYS OF QUOTATION/REPAIR WILL BE SOLD TO DEFRAY EXPENSES.'],
						  ],
		  });

		  doc.autoTable({
		    startY: doc.lastAutoTable.finalY + 10,
		    head: [
		      [
		        {
		          content: 'Signatures',
		          colSpan: 4,
		          styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
		        },
		      ],
		    ],
		    body: [
			    ['Name', thisName, 'Name', ''],
			    ['Date', soloJobDetails?.Signature_date?.slice(0, 10), 'Date', ''],
			    ['Signature', '', 'Signature', ''],
			  ],
			  bodyStyles: {
			    cellPadding: 10, // Adjust cell padding if necessary
			    minCellHeight: 200, // Increase cell height to fit the image
			  },
			  didDrawCell: (data) => {
			    // Insert image in the correct cell
			    if (data.row.index === 2 && data.column.index === 1) {
			      const cellWidth = data.cell.width - 4; // Reduce width slightly to avoid overflow
			      const imgWidth = cellWidth;
			      const imgHeight = imgWidth * 1.5; // Maintain aspect ratio
			      const xOffset = data.cell.x + 2; // Adjust x position
			      const yOffset = data.cell.y + (data.cell.height - imgHeight) / 2; // Center image vertically
			      doc.addImage(sign, 'JPEG', xOffset, yOffset, imgWidth, imgHeight);
			    }
			  },
			  willDrawCell: (data) => {
		    // Increase the height of the specific cell before drawing
		    if (data.row.index === 2) {
		      data.cell.height = 90; // Set the desired height for the cell containing the image
		    }
		  },
		    theme: 'grid',
		    bodyStyles: { font: 'helvetica', cellWidth: 'auto', minCellWidth: 110 },
			  columnStyles: {
		        0: { fillColor: [240, 240, 240] }, // Apply red fillColor to the first column
		        2: { fillColor: [240, 240, 240] } // Apply red fillColor to the third column
	    	}
		  });

		doc.save('table.pdf');
	}

	const scope = async() => {
			var doc = new jsPDF('p', 'pt');

			const now = new Date();

			const techBody = [];

			const techBodyReal = [];
			//console.log(Assigned_to);
			if(soloJobDetails?.Assigned_to !== 'none' || soloJobDetails?.Assigned_to == null || soloJobDetails?.Assigned_to == '')
			{
				console.log(JSON.parse(soloJobDetails?.Assigned_to));
				JSON.parse(soloJobDetails?.Assigned_to).forEach(async(tech) => {
						if(tech.value == 'none' || tech.value == null || tech.value == '')
						{
							techBody.push(['null']);
						}
						else
						{
							const res = await fetch(`https://api.te-amo.co.za/public/api/stuff/by/${tech.value}`);
							const data = await res.json();

							techBody.push([`${data[0].Name} ${data[0].Last_Name}`]);
						}
				});
			}
			

			//console.log(techBody);
			techBody.forEach((tech) => {
				console.log(`${tech.Name} ${tech.Last_Name}`);
					techBodyReal.push([`${tech.Name} ${tech.Last_Name}`]);
			});

			console.log(techBodyReal);

			/*data.forEach((tech) => {
					tech.push([`${tech.Name} ${tech.Last_Name}`]);
			})*/

			const res2 = await fetch(`https://api.te-amo.co.za/public/api/start/get/all?jobId=${soloJobDetails?.id}`);
			const data2 = await res2.json();

			console.log(data2);

			const api_key = 'AIzaSyDENioKxXlEV1wxt30v4YDNo1QuvOryxKU';
			const resDistance = await fetch(`https://api.te-amo.co.za/public/api/tasks/cal/distance?address=${address}&origin=${origin}&API_KEY=${api_key}`);
			const dataDistance = await resDistance.json();

			const formattedData = data2.map((startEnd) => {
			  const startDate = new Date(startEnd.Start);
			  const endDate = new Date(startEnd.End);
			  const hourDifference = Math.abs(endDate - startDate) / 36e5; // Calculate difference in hours

			  return [
			    startDate.toLocaleString('en-GB', { hour12: false }),
			    endDate.toLocaleString('en-GB', { hour12: false }),
			    hourDifference.toFixed(3), // Format to 2 decimal places
			    `${dataDistance.distance} ${dataDistance.duration}`
			  ];
			});

			doc.addImage(Logo, 'JPEG', 40, 10 );

			doc.setFont('helvetica', 'normal'); // Set font and style
			doc.setFontSize(9); // Set font size
			doc.text('Te-Amo Business Solutions', 440, 20);
			doc.text('2 Nelson Crescent, Malvern, Queensburgh, 4055', 355, 30);
			doc.text('0314633124', 500, 40);
			doc.text('help@te-amo.co.za', 472, 50);
			doc.text('Company No.: 2016/163396/07', 425, 60);
			doc.text('VAT Reg. No.: 4900275571', 440, 70);

			//doc.setFont('helvetica', 'normal'); // Set font and style
			doc.setFontSize(9); // Set font size
			doc.setTextColor(150, 150, 150);
			//doc.text('Theme "plain"', 14, doc.lastAutoTable.finalY + 10)
			//let bodyScope = soloJobDetails?.Description_of_job?soloJobDetails?.Description_of_job:(soloJobDetails?.Task_Name?soloJobDetails?.Task_Name:soloJobDetails?.Intallation_type);
		  doc.autoTable({
		    head: [
		      [
		        {
		          content: 'Job Scope',
		          colSpan: 1,
		          styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
		        },
		      ],
		    ],
		    body: [
						    [soloJobDetails?.Description_of_job?soloJobDetails?.Description_of_job:(soloJobDetails?.Task_Name?soloJobDetails?.Task_Name:soloJobDetails?.Intallation_type)],
						  ],
		    startY: 90,
		    theme: 'grid',
		  });
		  doc.setTextColor(50, 50, 50);
		  doc.text(`DATE                                      :${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`, 40, doc.lastAutoTable.finalY + 20, {  fontSize: 10, fontStyle: 'bold' });
		  doc.text(`CUSTOMER NAME                :${thisName} ${latName}`, 40, doc.lastAutoTable.finalY + 40, {  fontSize: 10, fontStyle: 'bold' });
		  doc.text(`CUSTOMER ADDRESS         :${address}`, 40, doc.lastAutoTable.finalY + 60, {  fontSize: 10, fontStyle: 'bold' });
		  //doc.text('                                                _______________________', 40, 250, {  fontSize: 10, fontStyle: 'bold' });

		  doc.text('QUOTE NO:', 480, doc.lastAutoTable.finalY + 20, {  fontSize: 10, fontStyle: 'bold' });
		  doc.text('___________', 480, doc.lastAutoTable.finalY + 30, {  fontSize: 10, fontStyle: 'bold' });
		  doc.text('INVOICE NO:', 480, doc.lastAutoTable.finalY + 50, {  fontSize: 10, fontStyle: 'bold' });
		  doc.text('___________', 480, doc.lastAutoTable.finalY + 60, {  fontSize: 10, fontStyle: 'bold' });

		  autoTable(doc, {
			  head: [
							  [
							    {
							      content: 'Technician Name',
							      styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
							    },
							    {
							      content: 'Signature',
							      styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
							    },
							  ],
							],
			  body: techBody,
			  startY: doc.lastAutoTable.finalY + 80,
			  theme: 'grid',
			  columnStyles: { 0: { cellWidth: 258 }, 1: { cellWidth: 258 } }
			});

			autoTable(doc, {
			  head: [
							  [
							    {
							      content: 'Start Time',
							      styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
							    },
							    {
							      content: 'Finish Time',
							      styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
							    },
							    {
							      content: 'Hours',
							      styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
							    },
							    {
							      content: 'KMS',
							      styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
							    },
							  ],
							],
			  body:formattedData,
			  styles: {
			    overflow: 'linebreak',
			    //cellPadding: 5,
			    fontSize: 10,
			    valign: 'middle',
			    halign: 'center', // Align text in the cells to the center
			  },
			  headStyles: {
			    fillColor: [220, 220, 220],
			    textColor: [40, 40, 40],
			    fontStyle: 'bold',
			    halign: 'center', // Align header text to the center
			  },
			  //startY: 420,
			  theme: 'grid',
			  columnStyles: { 0: { cellWidth: 129 }, 1: { cellWidth: 129 }, 2: { cellWidth: 129 }, 3: { cellWidth: 129 } }
			});

			
			doc.autoTable({
		    head: [
		      [
		        {
		          content: 'Extras',
		          colSpan: 1,
		          styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
		        },
		      ],
		    ],
		    body: [
						    [''],
						    [''],
						    [''],
						    [''],
						    // ...
						  ],
		    //startY: doc.lastAutoTable.finalY + 14,
		    theme: 'grid',
		    columnStyles: { 0: { cellWidth: 'auto' } }
		  });

		  doc.save('table.pdf');
	}

	const Assessment = async() => {

			const res = await fetch(`https://api.te-amo.co.za/public/api/items/get?jobId=${soloJobDetails?.id}`);
			const data = await res.json();

			console.log(data);

			var doc = new jsPDF('p', 'pt');
			doc.addImage(Logo, 'JPEG', 40, 10 );

			doc.setFont('helvetica', 'normal'); // Set font and style
			doc.setFontSize(9); // Set font size
			doc.text('Te-Amo Business Solutions', 440, 20);
			doc.text('2 Nelson Crescent, Malvern, Queensburgh, 4055', 355, 30);
			doc.text('0314633124', 500, 40);
			doc.text('help@te-amo.co.za', 472, 50);
			doc.text('Company No.: 2016/163396/07', 425, 60);
			doc.text('VAT Reg. No.: 4900275571', 440, 70);

			doc.setFont('helvetica', 'bold'); // Set font and style
			doc.setFontSize(16);
			doc.text('Assessment', 255, 110);

			doc.setFont('helvetica', 'normal'); // Set font and style
			doc.setFontSize(9);

			autoTable(doc, {
			  head: [
		      [
		        {
		          content: 'Assessment',
		          colSpan: 4,
		          styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
		        },
		      ],
		    ],
			  body: [
			    ['CUSTOMER NAME:', thisName, 'CUSTOMER CONTACT:', phone],
			    ['', '', '', ''],
			    ['CUSTOMER ADDRESS:', address, 'CUSTOMER EMAIL:', thisEmail],
			    ['', '', '', ''],
			    ['', '', 'ASSESSED BY:', ''],
			  ],
			  theme: 'grid',
			  startY: 120,
			  columnStyles: { 0: { cellWidth: 125, fontStyle: 'bold' }, 1: { cellWidth: 'auto' }, 2: { cellWidth: 125, fontStyle: 'bold' }, 3: { cellWidth: 'auto' } }
			})

			let leftOverItems = [];
			let mainItems = [];

			await data.forEach((item) => {
					mainItems.push([item.item, item.qty]);
			});

			doc.autoTable({
		    head: [
		      [
		        {
		          content: 'Work Scope',
		          colSpan: 1,
		          styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
		        },
		      ],
		    ],
		    body: [
						    [soloJobDetails?.Description_of_job?soloJobDetails?.Description_of_job:(soloJobDetails?.Task_Name?soloJobDetails?.Task_Name:soloJobDetails?.Intallation_type)],
						  ],
		    startY: doc.lastAutoTable.finalY + 20,
		    theme: 'grid',
		  });

		  autoTable(doc, {
			  head: [['Item', 'Qty']],
			  body: mainItems,
			  theme: 'grid',
			  startY: doc.lastAutoTable.finalY + 20,
			  columnStyles: { 0: { cellWidth: 'auto', fontStyle: 'bold' }, 1: { cellWidth: 'auto' }, 2: { cellWidth: 125, fontStyle: 'bold' }, 3: { cellWidth: 'auto' } },
			  headStyles: {
			    fillColor: [220, 220, 220], // black background
			    textColor: [40, 40, 40], // white text
			    fontStyle: 'bold',
			    halign: 'center' // horizontal alignment
			  }
			});

		  /*doc.setFont('helvetica', 'normal'); // Set font and style
			doc.setFontSize(9);

			doc.text('ITEMS REQUIRED & PRICE:', 40, doc.lastAutoTable.finalY + 20);

			doc.text('DVR:', 162, doc.lastAutoTable.finalY + 40);

			await data.forEach((item) => {
					if(item.item	== 'DVR')
					{
						doc.text(`X${item.qty}`, 190, doc.lastAutoTable.finalY + 40);
						doc.text('R_____________', 470, doc.lastAutoTable.finalY + 40);
					}
					else if(item.item	== 'CAMERAS')
					{
						doc.text(`X${item.qty}`, 190, doc.lastAutoTable.finalY + 60);
						doc.text('R_____________', 470, doc.lastAutoTable.finalY + 60);
					}
					else if(item.item	== 'DRIVE')
					{
						doc.text(`X${item.qty}`, 190, doc.lastAutoTable.finalY + 80);
						doc.text('R_____________', 470, doc.lastAutoTable.finalY + 80);
					}
					else if(item.item == 'POWER')
					{
						doc.text(`X${item.qty}`, 190, doc.lastAutoTable.finalY + 100);
						doc.text('R_____________', 470, doc.lastAutoTable.finalY + 100);
					}
					else if(item.item == 'CABLE')
					{
						doc.text(`X${item.qty}`, 190, doc.lastAutoTable.finalY + 120);
						doc.text('R_____________', 470, doc.lastAutoTable.finalY + 120);
					}
					else if(item.item == 'FLYLEAD')
					{
						doc.text(`X${item.qty}`, 190, doc.lastAutoTable.finalY + 180);
						doc.text('R_____________', 470, doc.lastAutoTable.finalY + 180);
					}
					else if(item.item == 'VIDEO BALUNS')
					{
						doc.text(`X${item.qty}`, 190, doc.lastAutoTable.finalY + 200);
						doc.text('R_____________', 470, doc.lastAutoTable.finalY + 200);
					}
					else if(item.item == 'WATERPROOF CAMERA BOXES')
					{
						doc.text(`X${item.qty}`, 190, doc.lastAutoTable.finalY + 220);
						doc.text('R_____________', 470, doc.lastAutoTable.finalY + 220);
					}
					else
					{
						leftOverItems.push({
								item: item.item,
								qty: item.qty
						});
					}
			});

			let lastY = 260;

			leftOverItems.forEach((item, index) => {
					if(index == 0)
					{
						doc.text(`${item.item}`, 190, doc.lastAutoTable.finalY + lastY);
						doc.text(`X${item.qty}`, 290, doc.lastAutoTable.finalY + lastY);
						doc.text('R_____________', 470, doc.lastAutoTable.finalY + lastY);

						lastY = lastY + 20;
					}
					else
					{
						doc.text(`${item.item}`, 190, doc.lastAutoTable.finalY + lastY);
						doc.text(`X${item.qty}`, 290, doc.lastAutoTable.finalY + lastY);
						doc.text('R_____________', 470, doc.lastAutoTable.finalY + lastY);

						lastY = lastY + 20;
					}
					
			});
			
			doc.text('CAMERAS:', 137, doc.lastAutoTable.finalY + 60);
			doc.text('HARD DRIVE:', 127, doc.lastAutoTable.finalY + 80);
			doc.text('POWER SUPPLY:', 110, doc.lastAutoTable.finalY + 100);
			doc.text('CABLE:', 152, doc.lastAutoTable.finalY + 120);

			doc.text('CONSUMABLES & CONNECTORS:', 40, doc.lastAutoTable.finalY + 160);
			doc.text('FLYLEADS:', 139, doc.lastAutoTable.finalY + 180);
			doc.text('VIDEO BALUNS:', 119, doc.lastAutoTable.finalY + 200);
			doc.text('WATERPROOF CAMERA BOXES:', 46, doc.lastAutoTable.finalY + 220);

			doc.text('ADDITIONAL ITEMS:', 100, doc.lastAutoTable.finalY + 260);

			doc.setFont('helvetica', 'bold'); // Set font and style
			doc.setFontSize(12);
			doc.text(`Total`, 430, doc.lastAutoTable.finalY + lastY + 20);
			doc.text('R_____________', 470, doc.lastAutoTable.finalY + lastY + 20);

			//autoTable.default(doc, { html: '#my-table' })*/
			doc.save('table.pdf');
	}

	useEffect(() => {
						setCusId(idReal);
						localStorage.setItem("cusId", JSON.stringify(idReal));
			       const getCustomer = async(id) => {
			       console.log('good');
			      const res = await fetch(`https://api.te-amo.co.za/public/api/customers/${idReal}`);
			      const data = await res.json();

			      //setSoloCustomer(data);
			      console.log(cusId);
			      console.log(soloJobDetails);

			      //setSoloJobDetails(data2);
			      //return data;

			      setThisName(data[0].Name);
			      setLatName(data[0].Last_Name);
			      setThisEmail(data[0].Email);
			      setPhone(data[0].Phone_Number);
			      setAddress(data[0].Address);

			      const response = await fetch(`https://api.te-amo.co.za/public/api/tasks/view/sign/${soloJobDetails?.id}`);
						const contentType = response.headers.get("content-type");
						const blob = await response.blob();
						const reader = new FileReader();
					    reader.onloadend = () => {
					      setDataUrl(reader.result);
					    };
					  reader.readAsDataURL(blob);
						console.log(dataUrl);
			  }
			  console.log(jobId);
			  getCustomer(cusId);

			  async() => {
		      	const response = await fetch(`https://api.te-amo.co.za/public/api/tasks/view/sign/${soloJobDetails?.id}`);
				const contentType = response.headers.get("content-type");
				const blob = await response.blob();
				
				const reader = new FileReader();
			    reader.onloadend = () => {
			      setDataUrl(reader.result);
			    };
			  	reader.readAsDataURL(blob);
		      }

    },[]);

	const submit = async (e) => {
		e.preventDefault();
		//console.log(custObj);
		setSpinner(true);
		const res = await fetch(`https://api.te-amo.co.za/public/api/customers/${cusId}/update`, {

              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({name: thisName, last: latName, phone: phone, email: thisEmail, address: address}),

          });

		  if (res.ok) {
            //throw new Error('Network response was not ok');
		  			setSpinner(false);
            toast.success('Customer updated successfully', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
          else
          {
          	setSpinner(false);
            toast.error('Customer failed to updated please check network connection or contact your service provider!!!', {
	            position: "top-right",
	            autoClose: 5000,
	            hideProgressBar: false,
	            closeOnClick: true,
	            pauseOnHover: true,
	            draggable: true,
	            progress: undefined,
	            theme: "dark",
            });
          }
	}
	const thisSetName = (e) =>
	{
		setThisName(e);
		setSave(false);
	}

	const thisSetLastName = (e) =>
	{
		setLatName(e);
		setSave(false);
	}

	const thisSetEmail = (e) =>
	{
		setThisEmail(e);
		setSave(false);
	}

	const thisSetPhone = (e) =>
	{
		setPhone(e);
		setSave(false);
	}

	const thisSetAddress = (e) =>
	{
		setAddress(e);
		setSave(false);
	}

	const showExtn = () => {
      const extn = document.getElementById('extnJobCard');
      const extn2 = document.getElementById('extnJobScope');
      const extn3 = document.getElementById('extnJobAssess');

      extn.style.display = "block";
      extn2.style.display = "block";
      extn3.style.display = "block";
  }
  const exitExtn = () => {
      const extn = document.getElementById('extnJobCard');
      const extn2 = document.getElementById('extnJobScope');
      const extn3 = document.getElementById('extnJobAssess');

      extn.style.display = "none";
      extn2.style.display = "none";
      extn3.style.display = "none";
  }

	return(
		<>
			{
				!dataUrl?OverlaySpinner:''
			}
			<div className="contentHeader shadow-sm sticky-top"><h4 className="TaskContentHeading">Customer datails (edit) </h4></div>

        	<div className="maiSecNave">

        			<div className="row">

        				<div className="col-md-9 mt-1">
	        				<Link to="/">Dashboard</Link> > <Link to="/customers">Customers</Link> ><b>{`CS${idReal}`}</b>
	        			</div>

	        			<div className="col-md-3">
	        				<div className="row">
	        					<div className="col-md-5">
	        							<span className="btn btn-dark mainPrintBtn" onMouseEnter={showExtn} onMouseLeave={exitExtn}>
	        								Print <FontAwesomeIcon icon={faChevronDown} className="icon" />
	        								{/*<ul className="ulDropDownPrint">
	        									<li><strong>Job Card</strong></li>
	        									<li><strong>Job Scope</strong></li>
	        								</ul>*/}
	        								<small className="btn-extnJobCard shadow" id="extnJobCard" onClick={() => table()}><strong>Job Card</strong></small>
	        								<small className="btn-extnJobScope shadow" id="extnJobScope" onClick={() => scope()}><strong>Descr</strong></small>
	        								<small className="btn-assessment shadow" id="extnJobAssess" onClick={() => Assessment()}><strong>Asses Doc</strong></small>
	        							</span>
	        					</div>
	        					<div className="col-md-7">
	        							<span className="btn btn-outline-dark">Close Job</span>
	        					</div>
	        				</div>
	        			</div>

        			</div>
        	</div>

        	<div className="TableArea">

				<Tabs />

				<form className="editForm" onSubmit={submit}><br />
					<div className="form-group">
					    <label htmlFor="exampleFormControlTextarea1">Name</label>
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Name" name="task" value={thisName} onChange={(e) => thisSetName(e.target.value)} />
					</div><br />

					<div className="form-group">
					    <label htmlFor="exampleFormControlTextarea1">Last name</label>
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last name" name="task" value={latName} onChange={(e) => thisSetLastName(e.target.value)}/>
					</div><br />

					<div className="form-group">
					    <label htmlFor="exampleFormControlTextarea1">Email</label>
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" name="task" value={thisEmail} onChange={(e) => thisSetEmail(e.target.value)}/>
					</div><br />

					<div className="form-group">
					    <label htmlFor="exampleFormControlTextarea1">Phone</label>
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone" name="task" value={phone} onChange={(e) => thisSetPhone(e.target.value)}/>
					</div><br />

					<div className="form-group">
					    <label htmlFor="exampleFormControlTextarea1">Location</label>
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Location" name="task" value={address} onChange={(e) => thisSetAddress(e.target.value)} />
					</div><br />

					<input type="submit" value="Save" className="btn btn-dark" disabled={save} />
				</form>
			</div>
		</>
	)
}

export default SoloJob